import { DonutChart } from "@tremor/react";
import { formatDollarsWithoutCents } from "../../src/formatting";
import Skeleton from "react-loading-skeleton";
import * as _ from "lodash";

type DonutChartSectionProps = {
  loading: boolean;
  data: {
    label: string;
    color: string;
    value: number; // Dollars
  }[];
  colors: string[];
  displayMode: "count" | "dollars";
};

function formatDisplayValue(value: number, displayMode: "count" | "dollars") {
  if (displayMode === "dollars") {
    return formatDollarsWithoutCents(value);
  }

  // Return the value as a string (but with commas)
  return value.toLocaleString();
}

export default function DonutChartSection({
  loading,
  data,
  colors,
  displayMode,
}: DonutChartSectionProps) {
  if (loading) {
    return (
      <div className="flex flex-col space-y-3 justify-start my-2 pt-3">
        {_.range(3).map((ind) => (
          <div key={ind}>
            <Skeleton className="mb-1" width={140} height={20} />
            <Skeleton width={100} height={14} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-row items-start justify-between h-[242px]">
      <ul role="list" className="space-y-2 w-fit mt-2">
        {data.map((item) => {
          const formattedValue = formatDisplayValue(item.value, displayMode);
          return (
            <LegendDisplayItem
              key={item.label}
              label={item.label}
              color={item.color}
              value={formattedValue}
            />
          );
        })}
      </ul>
      <DonutChart
        index="label"
        category="value"
        data={data}
        valueFormatter={(v) => formatDisplayValue(v, displayMode)}
        className="h-44 w-44 mr-2 mt-2 right-0 z-[1]"
        colors={colors}
        showTooltip
        showAnimation
      />
    </div>
  );
}

export function LegendDisplayItem({
  color,
  value,
  label,
}: {
  color: string;
  value: string;
  label: string;
}) {
  return (
    <div className="flex flex-row items-center hover:bg-zinc-50 rounded transition-all">
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className={`h-10 w-1 rounded bg-${color}`} />
      <div className="py-1 pr-2 pl-3">
        <div className="text-lg leading-6 font-medium">{value}</div>
        <div className="text-zinc-500 text-sm font-normal">{label}</div>
      </div>
    </div>
  );
}
