import ms from "ms";
import { formatDollarsWithoutCents } from "../../src/formatting";
import SectionedRow from "./SectionedRow";
import DeltaMetric from "./DeltaMetric";
import useTriageInsightsData from "./useTriageInsightsData";

export default function TriageInsightsSection() {
  const {
    loadingUnappliedRevenueData,
    loadingWithoutCoverageData,
    loadingTimeToApplyData,
    loadingBurndownData,
    unappliedRevenue,
    withoutCoverage,
    timeToApplyMs,
    burndown,
  } = useTriageInsightsData();

  return (
    <>
      <h2 className="text-2xl leading-8 font-medium text-zinc-900 pb-5">
        Triage Insights
      </h2>
      <SectionedRow showDivider={true}>
        {(unappliedRevenue.data.length > 0 || loadingUnappliedRevenueData) && (
          <DeltaMetric
            label="Unapplied Revenue"
            data={unappliedRevenue.data}
            displayValue={formatDollarsWithoutCents(
              unappliedRevenue.latestValue
            )}
            delta={unappliedRevenue.delta}
            goodDelta="negative"
            loading={loadingUnappliedRevenueData}
            mode="dollars"
          />
        )}
        {(withoutCoverage.data.length > 0 || loadingWithoutCoverageData) && (
          <DeltaMetric
            data={withoutCoverage.data}
            label="Without Coverage"
            displayValue={formatDollarsWithoutCents(
              withoutCoverage.latestValue
            )}
            delta={withoutCoverage.delta}
            goodDelta="negative"
            loading={loadingWithoutCoverageData}
            mode="dollars"
          />
        )}
        {(timeToApplyMs.data.length > 0 || loadingTimeToApplyData) && (
          <DeltaMetric
            data={timeToApplyMs.data}
            label="Time to Apply"
            displayValue={ms(timeToApplyMs.latestValue, { long: true })}
            delta={timeToApplyMs.delta}
            goodDelta="negative"
            loading={loadingTimeToApplyData}
            mode="time"
          />
        )}
        {(burndown.data.length > 0 || loadingBurndownData) && (
          <DeltaMetric
            data={burndown.data}
            label="Prior Period Burndown"
            displayValue={formatDollarsWithoutCents(burndown.latestValue)}
            delta={burndown.delta}
            goodDelta="positive"
            loading={loadingBurndownData}
            mode="dollars"
          />
        )}
      </SectionedRow>
    </>
  );
}
