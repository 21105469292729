import Select from "../../components/Select";
import usePeriodOptions, { PeriodOption } from "./usePeriodOptions";
import TriageInsightsSection from "./TriageInsightsSection";
import DirectBillSection from "./DirectBillSection";
import TriageDetailSection from "./TriageDetailSection";
import { useLoggedInUser } from "../../components/LoggedInUserProvider";
import { UserRole } from "@comulate/graphql-types";
import ROIMetrics from "./ROIMetrics";
import { FinancialCycleMetrics } from "./FinancialCycleMetrics";

export const Dashboard = () => {
  const { user, organization } = useLoggedInUser();

  const [selectedPeriod, setSelectedPeriod, allPeriodsOptions] =
    usePeriodOptions();

  return (
    <>
      <div className="flex flex-col h-full w-full py-7 overflow-y-scroll">
        <div
          className="max-w-8xl mx-auto w-full space-y-10"
          style={{
            paddingLeft: "calc(16px + (100vw - 100%))",
            paddingRight: 16,
          }}
        >
          <div className="space-y-5">
            <div className="flex justify-between">
              <div className="text-2xl leading-8 font-medium text-zinc-900">
                Direct Bill
              </div>
              <div>
                <Select
                  size="xs"
                  mainClasses="w-30 h-6"
                  menuClasses="w-48 right-0"
                  anchor="bottom end"
                  options={allPeriodsOptions}
                  selectedOption={selectedPeriod}
                  onChange={(option: PeriodOption) => setSelectedPeriod(option)}
                />
              </div>
            </div>
            <DirectBillSection
              selectedPeriodId={selectedPeriod ? selectedPeriod.id : null}
            />
          </div>
          {organization?.featureAccess.enableWorkflowInsights &&
            user?.role === UserRole.ADMIN && (
              <>
                <ROIMetrics
                  selectedPeriodId={selectedPeriod ? selectedPeriod.id : null}
                />
                <FinancialCycleMetrics
                  selectedPeriodId={selectedPeriod ? selectedPeriod.id : null}
                />
              </>
            )}
          {organization?.featureAccess.enableTriageAnalytics && (
            <div className="flex flex-col">
              <TriageInsightsSection />
              <TriageDetailSection />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
