import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React, { forwardRef } from "react";

export const Input = forwardRef(function Input(
  {
    className,
    ...props
  }: {
    className?: string;
    type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
  } & Omit<Headless.InputProps, "className">,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <span
      data-slot="control"
      className={clsx([
        className,
        // Basic layout
        "relative block w-full",
        // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
        "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.md)-1px)] before:bg-white before:shadow",
        // Focus ring
        "after:pointer-events-none after:absolute after:inset-0 after:rounded-md after:ring-1 after:ring-transparent after:focus-within:ring-green-500",
        // Disabled state
        "has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none",
        // Invalid state
        "before:has-[[data-invalid]]:shadow-red-500/10",
      ])}
    >
      <Headless.Input
        ref={ref}
        {...props}
        className={clsx([
          // TODO: Support date formats: https://catalyst.tailwindui.com/docs/input
          // Basic layout
          "relative block w-full appearance-none rounded-md px-[calc(theme(spacing[3])-2px)] py-[calc(theme(spacing[2])-2px)]",
          // Typography
          "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6",
          // Border
          "border border-zinc-300 data-[hover]:border-zinc-400/60 focus:border-green-600 data-[hover]:focus:border-green-600",
          // One alternative on border colors long term can be to move to a dark
          // color with an opacity, e.g. border-zinc-950/10 data-[hover]:border-zinc-950/20
          // The reason this is attractive is because it makes changing 'shades'
          // read easier and happen more consistently
          // Background color
          "bg-transparent",
          // Hide default focus styles
          "focus:outline-none",
          // Invalid state
          "data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500",
          // Disabled state
          "data-[disabled]:border-zinc-950/20 data-[disabled]:cursor-not-allowed",
        ])}
      />
    </span>
  );
});
