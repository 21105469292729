import {
  FinancialCycleMetricsPeriodSummary,
  FinancialCycleMetricsQuery,
  ProcessFlowMetricsQuery,
  useFinancialCycleMetricsQuery,
  useProcessFlowMetricsQuery,
} from "@comulate/graphql-types";
import {
  classNames,
  formatDollarsCompact,
  formatDollarsWithoutCents,
  isNullOrUndefined,
  pluralize,
} from "../../src/formatting";
import { DateTime } from "luxon";
import * as _ from "lodash";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";
import SectionedRow from "./SectionedRow";
import DeltaMetric from "./DeltaMetric";
import { useMemo, useState } from "react";
import Menu, { EllipsisMenu } from "../../components/Menu";
import { Switch } from "@headlessui/react";
import {
  AreaChart,
  TooltipProps,
} from "../../components/tremor/TremorAreaChart";

export const FinancialCycleMetrics = ({
  selectedPeriodId,
}: {
  selectedPeriodId: string | null;
}) => {
  const [includeUnlinkedDeposits, setIncludeUnlinkedDeposits] = useState(true);

  const {
    data: financialCycleMetricsData,
    loading: financialCycleMetricsLoading,
  } = useFinancialCycleMetricsQuery({
    variables: {
      periodId: selectedPeriodId as string,
    },
    skip: isNullOrUndefined(selectedPeriodId),
  });
  const { data: processFlowMetricsData } = useProcessFlowMetricsQuery({
    variables: {
      periodId: selectedPeriodId as string,
    },
    skip: isNullOrUndefined(selectedPeriodId),
  });

  const currFinancialCycleMetrics =
    financialCycleMetricsData?.financialCycleMetricsSummary.currPeriod;

  const maxChartValue = useMemo(() => {
    if (!processFlowMetricsData) return undefined;

    const {
      cumulativeDepositRevenueAmounts,
      cumulativeLinkedDepositRevenueAmounts,
    } = processFlowMetricsData.processFlowMetrics;

    const amounts = includeUnlinkedDeposits
      ? cumulativeDepositRevenueAmounts
      : cumulativeLinkedDepositRevenueAmounts;

    if (amounts.length === 0) return undefined;

    const maxCashRevenue = Math.max(...amounts.map(({ value }) => value));
    const minCashRevenue = Math.min(...amounts.map(({ value }) => value));
    const avgCashRevenuePerDay =
      (maxCashRevenue - minCashRevenue) / amounts.length;

    const { daysInMonth } = DateTime.fromISO(amounts[0].date, { zone: "utc" });

    // Get projected value for the rest of the month to determine how high the
    // chart should go
    return avgCashRevenuePerDay * daysInMonth;
  }, [includeUnlinkedDeposits, processFlowMetricsData]);

  return (
    <div className="space-y-5 border-b">
      <div className="flex items-center justify-between">
        <div className="text-2xl leading-8 font-medium text-zinc-900">
          Cycle & Flow
        </div>
        <EllipsisMenu width={260}>
          <Menu.Group>
            <Menu.Item>
              <div className="w-full px-4 py-2 text-sm text-left text-zinc-700 flex items-center justify-between">
                <span>Include unlinked deposits</span>
                <Switch
                  checked={includeUnlinkedDeposits}
                  onChange={setIncludeUnlinkedDeposits}
                  className={classNames(
                    includeUnlinkedDeposits ? "bg-green-600" : "bg-zinc-200",
                    "relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  )}
                >
                  <span className="sr-only">Include unlinked deposits</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      includeUnlinkedDeposits
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
              </div>
            </Menu.Item>
          </Menu.Group>
        </EllipsisMenu>
      </div>
      <SectionedRow showDivider>
        <DeltaMetric
          label="Comulate Cycle Time"
          goodDelta="negative"
          mode="time"
          loading={financialCycleMetricsLoading}
          displayValue={formatDays(
            currFinancialCycleMetrics?.comulateCycleTimeDays
          )}
          delta={getMetricDelta(
            financialCycleMetricsData,
            "comulateCycleTimeDays"
          )}
          data={null}
        />
        <DeltaMetric
          label="Cash → Statement"
          goodDelta="negative"
          mode="time"
          loading={financialCycleMetricsLoading}
          displayValue={formatDays(
            currFinancialCycleMetrics?.cashToStatementDays
          )}
          delta={getMetricDelta(
            financialCycleMetricsData,
            "cashToStatementDays"
          )}
          data={null}
        />
        <DeltaMetric
          label="Full Cycle Time"
          goodDelta="negative"
          mode="time"
          loading={financialCycleMetricsLoading}
          displayValue={formatDays(currFinancialCycleMetrics?.fullCycleDays)}
          delta={getMetricDelta(financialCycleMetricsData, "fullCycleDays")}
          data={null}
        />
        <DeltaMetric
          label="Statements w/o Deposits "
          goodDelta="negative"
          mode="dollars"
          loading={financialCycleMetricsLoading}
          displayValue={
            currFinancialCycleMetrics?.statementsWithoutDepositsAmount
              ? formatDollarsWithoutCents(
                  currFinancialCycleMetrics?.statementsWithoutDepositsAmount
                )
              : "–"
          }
          delta={null}
          data={null}
        />
      </SectionedRow>
      <div className="w-full divide-y">
        <div style={{ height: 358 }}>
          {processFlowMetricsData &&
          processFlowMetricsData.processFlowMetrics
            .cumulativeDepositRevenueAmounts.length > 0 ? (
            <AreaChart
              fill="solid"
              type="stacked"
              index="date"
              className="h-full"
              showLegend={false}
              showXAxis={false}
              maxValue={maxChartValue}
              data={formatProcessFlowData(
                processFlowMetricsData.processFlowMetrics,
                {
                  includeUnlinkedDeposits,
                }
              )}
              categories={["posted", "reconciled", "production", "cash"]}
              valueFormatter={formatDollarsCompact}
              colors={["blue", "green", "amber", "sand"]}
              customTooltip={ChartTooltip}
            />
          ) : processFlowMetricsData &&
            processFlowMetricsData.processFlowMetrics
              .cumulativeDepositRevenueAmounts.length === 0 ? (
            <div className="px-10 py-20 w-max m-auto text-xs text-slate-600">
              There are no results for this time period.
            </div>
          ) : (
            <div className="flex flex-grow justify-center items-center h-full">
              <div className="flex flex-shrink items-end space-x-3">
                <Skeleton width="20px" height="60px" />
                <Skeleton width="20px" height="90px" />
                <Skeleton width="20px" height="120px" />
                <Skeleton width="20px" height="150px" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const formatDays = (metric: number | null | undefined) => {
  if (!metric) return "–";

  return pluralize(parseFloat(metric.toFixed(1)), "days");
};

const getMetricDelta = (
  data: FinancialCycleMetricsQuery | undefined,
  key: keyof Omit<FinancialCycleMetricsPeriodSummary, "__typename">
) => {
  const currPeriodValue = data?.financialCycleMetricsSummary.currPeriod[key];
  const prevPeriodValue = data?.financialCycleMetricsSummary.prevPeriod[key];

  if (!currPeriodValue || !prevPeriodValue) return null;

  return currPeriodValue / prevPeriodValue - 1;
};

type ProcessFlowPayload = {
  date: string;
  posted: number | null;
  reconciled: number | null;
  production: number | null;
  cash: number | null;
  original: {
    posted: number | null;
    reconciled: number | null;
    production: number | null;
    cash: number | null;
  };
};

const PROCESS_FLOW_COLORS = {
  posted: "bg-blue-600",
  reconciled: "bg-green-600",
  production: "bg-amber-600",
  cash: "bg-sand-600",
};

const formatProcessFlowData = (
  data: ProcessFlowMetricsQuery["processFlowMetrics"],
  { includeUnlinkedDeposits = true }: { includeUnlinkedDeposits?: boolean } = {}
): ProcessFlowPayload[] => {
  const startDt = DateTime.fromISO(
    _.sortBy(data.cumulativeDepositRevenueAmounts, "date")[0].date,
    { zone: "utc" }
  );

  const cumulativeDepositRevenueAmountsByDate = _.keyBy(
    includeUnlinkedDeposits
      ? data.cumulativeDepositRevenueAmounts
      : data.cumulativeLinkedDepositRevenueAmounts,
    "date"
  );
  const cumulativeProductionRevenueAmountsByDate = _.keyBy(
    data.cumulativeProductionRevenueAmounts,
    "date"
  );
  const cumulativeReconciledRevenueAmountsByDate = _.keyBy(
    data.cumulativeReconciledRevenueAmounts,
    "date"
  );
  const cumulativePostedRevenueAmountsByDate = _.keyBy(
    data.cumulativePostedRevenueAmounts,
    "date"
  );

  return _.range(startDt.daysInMonth).map((days) => {
    const date = startDt.plus({ days }).toISO();
    const formattedDate = DateTime.fromISO(date, { zone: "utc" }).toFormat(
      "ccc LLL d"
    );

    const cashRevenue = cumulativeDepositRevenueAmountsByDate[date];
    const productionRevenue = cumulativeProductionRevenueAmountsByDate[date];
    const reconciledRevenue = cumulativeReconciledRevenueAmountsByDate[date];
    const postedRevenue = cumulativePostedRevenueAmountsByDate[date];

    if (isNullOrUndefined(cashRevenue)) {
      return {
        date: formattedDate,
        posted: null,
        reconciled: null,
        production: null,
        cash: null,
        original: {
          posted: null,
          reconciled: null,
          production: null,
          cash: null,
        },
      };
    }

    return {
      date: formattedDate,
      // Use the difference between the current and previous data points to
      // support stacking values
      posted: postedRevenue.value,
      reconciled: reconciledRevenue.value - postedRevenue.value,
      production: productionRevenue.value - reconciledRevenue.value,
      cash: cashRevenue.value - productionRevenue.value,
      original: {
        posted: postedRevenue.value,
        reconciled: reconciledRevenue.value,
        production: productionRevenue.value,
        cash: cashRevenue.value,
      },
    };
  });
};

/**
 * FORKED FROM @tremor/react
 *
 * @param param0
 * @returns
 */
const ChartTooltip = ({ active, payload, label }: TooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={clsx(
          // base
          "rounded-md border text-sm shadow-md",
          // border color
          "border-gray-200 dark:border-gray-800",
          // background color
          "bg-white dark:bg-gray-950"
        )}
      >
        <div className={clsx("border-b border-inherit px-4 py-2")}>
          <p
            className={clsx(
              // base
              "font-medium",
              // text color
              "text-gray-900 dark:text-gray-50"
            )}
          >
            {label}
          </p>
        </div>
        <div className={clsx("space-y-1 px-4 py-2")}>
          {payload.reverse().map((payloadItem, index) => {
            const payload = payloadItem.payload as ProcessFlowPayload;
            const dataKey = payloadItem.category as keyof ProcessFlowPayload;

            const value = payload[dataKey] as number | null;

            if (isNullOrUndefined(value)) {
              return null;
            }

            return (
              <div
                key={`id-${index}`}
                className="flex items-center justify-between space-x-8"
              >
                <div className="flex items-center space-x-2">
                  <span
                    aria-hidden="true"
                    className={clsx(
                      "h-[3px] w-3.5 shrink-0 rounded-full",
                      PROCESS_FLOW_COLORS[dataKey]
                    )}
                  />
                  <p
                    className={clsx(
                      // base
                      "whitespace-nowrap text-right",
                      // text color
                      "text-gray-700 dark:text-gray-300"
                    )}
                  >
                    {_.capitalize(dataKey)}
                  </p>
                </div>
                <p
                  className={clsx(
                    // base
                    "whitespace-nowrap text-right font-medium tabular-nums",
                    // text color
                    "text-gray-900 dark:text-gray-50"
                  )}
                >
                  {formatDollarsWithoutCents(value)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};
