import { CustomTooltipProps, LineChart } from "@tremor/react";
import { useMemo } from "react";
import { clsx } from "clsx";
import {
  DateFormat,
  formatDate,
  formatDeltaPercent,
  formatDollarsWithoutCents,
} from "../../src/formatting";
import Skeleton from "react-loading-skeleton";
import ms from "ms";
import { DateTime } from "luxon";
import {
  isNotNullAndNotUndefined,
  isNullOrUndefined,
} from "@comulate/file-utils";

type DeltaMetricProps = {
  loading: boolean;
  /**
   * Individual data points to be rendered as a sparkline chart. If null, the line
   * chart is not rendered.
   */
  data:
    | {
        date: string;
        value: number;
      }[]
    | null;
  label: string;
  displayValue: string;
  /**
   * A number between [-1:1] which represents the change in value. It is
   * displayed as a red or green +-XX.X% next to the label of the section.
   *
   * If delta is null, we skip displaying a delta.
   * */
  delta: number | null;
  mode: "dollars" | "time";
  goodDelta: "positive" | "negative";
};

export default function DeltaMetric({
  loading,
  data,
  label,
  displayValue,
  delta,
  mode,
  goodDelta,
}: DeltaMetricProps) {
  const formattedPercentage = isNullOrUndefined(delta)
    ? delta
    : formatDeltaPercent(delta);

  const badgeColorScheme = useMemo(
    () => makePercentDeltaStyles(delta ?? 0, goodDelta),
    [delta, goodDelta]
  );

  const labeledData = data?.map((d) => ({
    ...d,
    [label]: d.value,
    date: formatDate(
      DateTime.fromISO(d.date, {
        zone: "utc",
      }),
      DateFormat.DATE_NAME_SHORT
    ),
  }));

  // Height here is set to mirror actual content height to prevent layout shift
  if (loading) {
    return (
      <div className="w-full space-y-2 py-5">
        <div className="w-full flex flex-row items-center justify-start space-x-2">
          <Skeleton height={20} width={190} />
        </div>
        {labeledData ? (
          <Skeleton height={40} width={120} className="mt-2 mb-[15px]" />
        ) : (
          <Skeleton height={32} width={120} className="mt-2" />
        )}
      </div>
    );
  }

  return (
    <div className="w-full space-y-2 py-5">
      <div className="w-full flex flex-row items-center justify-start space-x-2">
        <h3 className="text-base leading-6 font-medium text-zinc-600">
          {label}
        </h3>
        {isNotNullAndNotUndefined(formattedPercentage) && (
          <div
            className={clsx(
              badgeColorScheme,
              "text-sm font-semibold px-1 py-[0.5] rounded"
            )}
          >
            {formattedPercentage}
          </div>
        )}
      </div>
      <div className="text-2xl font-normal tabular-nums text-zinc-900 truncate">
        {displayValue}
      </div>
      {labeledData && (
        <LineChart
          data={labeledData}
          categories={[label]}
          index="date"
          className="w-full text-green-200 h-[35px]"
          colors={["#0F4F48"]}
          showLegend={false}
          showYAxis={false}
          showXAxis={false}
          showGridLines={false}
          startEndOnly={true}
          valueFormatter={
            mode === "dollars"
              ? formatDollarsWithoutCents
              : (t) => ms(t, { long: true })
          }
          showAnimation
          customTooltip={(tooltipProps) => (
            <ChartTooltip
              {...tooltipProps}
              formatter={
                mode === "dollars"
                  ? formatDollarsWithoutCents
                  : (t) => ms(t, { long: true })
              }
            />
          )}
        />
      )}
    </div>
  );
}

// For our line charts currently, going down is good, so we want to show green
const makePercentDeltaStyles = (
  percentage: number,
  goodDeltaDirection: "positive" | "negative"
) => {
  const deltaDirection = percentage > 0 ? "positive" : "negative";

  const deltaIsGood = deltaDirection === goodDeltaDirection;

  return clsx({
    "text-green-700 bg-green-50": deltaIsGood,
    "text-red-700 bg-red-50": !deltaIsGood,
  });
};

const ChartTooltip = ({
  active,
  payload,
  label,
  formatter,
}: CustomTooltipProps & {
  formatter: (value: number) => string;
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={clsx(
          // base
          "rounded-md border text-sm shadow-md",
          // border color
          "border-gray-200 dark:border-gray-800",
          // background color
          "bg-white dark:bg-gray-950 translate-y-8"
        )}
      >
        <div className={clsx("border-b border-inherit px-4 py-2")}>
          <p
            className={clsx(
              // base
              "font-medium",
              // text color
              "text-gray-900 dark:text-gray-50"
            )}
          >
            {label}
          </p>
        </div>
        <div className={clsx("space-y-1 px-4 py-2")}>
          {payload.reverse().map(({ dataKey, payload }, index) => {
            if (isNullOrUndefined(dataKey)) {
              return null;
            }

            const originalValue = payload[dataKey];

            return (
              <div
                key={`id-${index}`}
                className="flex items-center justify-between space-x-8"
              >
                <div className="flex items-center space-x-2">
                  <span
                    aria-hidden="true"
                    className={clsx(
                      "h-[3px] w-3.5 shrink-0 rounded-full bg-[#0F4F48]"
                    )}
                  />
                  <p
                    className={clsx(
                      // base
                      "whitespace-nowrap text-right",
                      // text color
                      "text-gray-700 dark:text-gray-300"
                    )}
                  >
                    {dataKey}
                  </p>
                </div>
                <p
                  className={clsx(
                    // base
                    "whitespace-nowrap text-right font-medium tabular-nums",
                    // text color
                    "text-gray-900 dark:text-gray-50"
                  )}
                >
                  {formatter(originalValue)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};
