// Tremor focusRing [v0.0.1]

export const focusRing = [
  // base
  "outline outline-offset-2 outline-0 focus-visible:outline-2",
  // outline color
  "outline-blue-500 dark:outline-blue-500",
];

// Tremor focusInput [v0.0.1]

export const focusInput = [
  // base
  "focus:ring-2",
  // ring color
  "focus:ring-blue-200 focus:dark:ring-blue-700/30",
  // border color
  "focus:border-blue-500 focus:dark:border-blue-700",
];

// Tremor hasErrorInput [v0.0.1]

export const hasErrorInput = [
  // base
  "ring-2",
  // border color
  "border-red-500 dark:border-red-700",
  // ring color
  "ring-red-200 dark:ring-red-700/30",
];

// Tremor chartColors [v0.1.0]

export type ColorUtility = "bg" | "stroke" | "fill" | "text" | "lightText";

export const chartColors = {
  sand: {
    bg: "bg-sand-600",
    stroke: "stroke-sand-600",
    fill: "fill-sand-600",
    text: "text-sand-600",
    lightText: "text-sand-200",
  },
  amber: {
    bg: "bg-amber-600",
    stroke: "stroke-amber-600",
    fill: "fill-amber-600",
    text: "text-amber-600",
    lightText: "text-amber-200",
  },
  blue: {
    bg: "bg-blue-600",
    stroke: "stroke-blue-600",
    fill: "fill-blue-600",
    text: "text-blue-600",
    lightText: "text-blue-200",
  },
  emerald: {
    bg: "bg-emerald-600",
    stroke: "stroke-emerald-600",
    fill: "fill-emerald-600",
    text: "text-emerald-600",
    lightText: "text-emerald-200",
  },
  green: {
    bg: "bg-green-600",
    stroke: "stroke-green-600",
    fill: "fill-green-600",
    text: "text-green-600",
    lightText: "text-green-200",
  },
  violet: {
    bg: "bg-violet-600",
    stroke: "stroke-violet-600",
    fill: "fill-violet-600",
    text: "text-violet-600",
    lightText: "text-violet-200",
  },
  gray: {
    bg: "bg-gray-600",
    stroke: "stroke-gray-600",
    fill: "fill-gray-600",
    text: "text-gray-600",
    lightText: "text-gray-200",
  },
  cyan: {
    bg: "bg-cyan-600",
    stroke: "stroke-cyan-600",
    fill: "fill-cyan-600",
    text: "text-cyan-600",
    lightText: "text-cyan-200",
  },
  pink: {
    bg: "bg-pink-600",
    stroke: "stroke-pink-600",
    fill: "fill-pink-600",
    text: "text-pink-600",
    lightText: "text-pink-200",
  },
  lime: {
    bg: "bg-lime-600",
    stroke: "stroke-lime-600",
    fill: "fill-lime-600",
    text: "text-lime-600",
    lightText: "text-lime-200",
  },
  fuchsia: {
    bg: "bg-fuchsia-600",
    stroke: "stroke-fuchsia-600",
    fill: "fill-fuchsia-600",
    text: "text-fuchsia-600",
    lightText: "text-fuchsia-200",
  },
};

export type AvailableChartColorsKeys = keyof typeof chartColors;

export const AvailableChartColors: AvailableChartColorsKeys[] = Object.keys(
  chartColors
) as Array<AvailableChartColorsKeys>;

export const constructCategoryColors = (
  categories: string[],
  colors: AvailableChartColorsKeys[]
): Map<string, AvailableChartColorsKeys> => {
  const categoryColors = new Map<string, AvailableChartColorsKeys>();
  categories.forEach((category, index) => {
    categoryColors.set(category, colors[index % colors.length]);
  });
  return categoryColors;
};

export const getColorClassName = (
  color: AvailableChartColorsKeys,
  type: ColorUtility
): string => {
  const fallbackColor = {
    bg: "bg-gray-600",
    stroke: "stroke-gray-600",
    fill: "fill-gray-600",
    text: "text-gray-600",
  };
  return chartColors[color]?.[type] ?? fallbackColor[type];
};

// Tremor getYAxisDomain [v0.0.0]

export const getYAxisDomain = (
  autoMinValue: boolean,
  minValue: number | undefined,
  maxValue: number | undefined
) => {
  const minDomain = autoMinValue ? "auto" : minValue ?? 0;
  const maxDomain = maxValue ?? "auto";
  return [minDomain, maxDomain];
};

// Tremor hasOnlyOneValueForKey [v0.1.0]

export function hasOnlyOneValueForKey(
  array: any[],
  keyToCheck: string
): boolean {
  const val: any[] = [];

  for (const obj of array) {
    if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
      val.push(obj[keyToCheck]);
      if (val.length > 1) {
        return false;
      }
    }
  }

  return true;
}

// Tremor useOnWindowResize [v0.0.0]

import { useEffect } from "react";

export const useOnWindowResize = (handler: { (): void }) => {
  useEffect(() => {
    const handleResize = () => {
      handler();
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handler]);
};
